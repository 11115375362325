import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "France",
  "subtitle": "September 2015",
  "category": "Europe"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul">{`Turn on tolls and motorways on your GPS if you are using one or you'll end up driving local roads for hours`}</li>
      <li parentName="ul">{`Triple check the opening hours for restaurants; Many are closed on Sundays and in the middle of the day`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.thefork.com/"
        }}>{`The Fork`}</a>{` - Their version of Open Table.`}</li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Foie_gras"
        }}>{`Foie gras`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Macaron"
        }}>{`Macarons`}</a></li>
    </ul>
    <h2 {...{
      "id": "paris"
    }}>{`Paris`}</h2>
    <ul>
      <li parentName="ul">{`Not my kind of city`}</li>
    </ul>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Nkmp5acnAwWp1jqKA"
        }}>{`Les Papilles`}</a>{` - Make a reservation`}</li>
      <li parentName="ul">{`Bouillon Chartier - They are famous but they were so rude; Not my cup of tea`}</li>
    </ul>
    <h4 {...{
      "id": "macarons"
    }}>{`Macarons`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.pierreherme.com/"
        }}>{`Pierre Herme`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.sadaharuaoki.com/"
        }}>{`Sadaharu Aoki`}</a>{` - Love their tea flavors and try their Crème caramel`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.dalloyau.fr/"
        }}>{`Dalloyau`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/U2C552Fb8Qb5TpdcA"
        }}>{`Musée de l’Orangerie`}</a>{` - Monet's water lilies murals were really nice`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/C5yMvw6zsyU42DMa6"
        }}>{`Musee D’Orsay`}</a>{` - Closed on Mondays and long lines`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/W8taP54VoacEBeEw9"
        }}>{`Musee Marmottan Monet`}</a>{` - Closed Mondays but good audio guide`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/x5XPZbNaLBJivNDd9"
        }}>{`Palais Garnier`}</a>{` - Audio guide was good`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/bytxPtJaNMyDwhTd7"
        }}>{`Arc de Triomphe`}</a>{` - I got to go in for free around 7 PM. It’s not listed that way on the website though. The sunset was nice, but it was pretty crowded.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/dtpL1TQP1WSece627"
        }}>{`Citypharma`}</a>{` - Store was extremely packed full of foreign beauty shoppers`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/dUHUpZzQwkGJEuFr7"
        }}>{`Galeries Lafayette`}</a>{` - Nice view on Paris on the roof deck`}</li>
    </ul>
    <h2 {...{
      "id": "st-remy"
    }}>{`St. Remy`}</h2>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/JYd6JJSmmNdEpbiv6"
        }}>{`Maison Drouot`}</a>{` - Dinner service only`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/6CvPqtmwQgUj9ovM6"
        }}>{`Grand Marché Provençal de Saint-Rémy-de-Provence`}</a>{` - Wednesday market opened from morning until around noon with live music and lots of lavender for sale`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/YLWAMnaZJxZ38WYa9"
        }}>{`Château des Baux`}</a>{` - Castle and the village were both medieval but well preserved with great audio guide. Parking lot in the village.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/HaUKuVrY9LEVcW2JA"
        }}>{`Carrieres de Lumieres`}</a>{` - 5 minutes walk from Château des Baux but offers their own parking lot. I believe their immersive experience rotates.`}</li>
    </ul>
    <h2 {...{
      "id": "cassis"
    }}>{`Cassis`}</h2>
    <ul>
      <li parentName="ul">{`Avoid driving through Marseilles because traffic`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/xj37FTSSPetEQcJCA"
        }}>{`Parc national des Calanques`}</a>{` is gorgeous`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/7FDpw9W7kXJ9SsLFA"
            }}>{`Calanque de Port-Miou`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/jLAojWYy7o2sFN5K7"
            }}>{`Calanque de Port Pin`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "avignon"
    }}>{`Avignon`}</h2>
    <ul>
      <li parentName="ul">{`Driving here is NOT recommended. It was insanely narrow.`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/7hdv5Y8dQJmg2aHS9"
        }}>{`Popes Palace`}</a>
        <ul parentName="li">
          <li parentName="ul">{`There's a light show at night`}</li>
          <li parentName="ul">{`Daytime with with audio guide was good`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/7NoYjeEf2ZFe6m5U9"
        }}>{`Pont d’Avignon`}</a>{` - The view from afar is better... Save your $`}</li>
    </ul>
    <h2 {...{
      "id": "lyon"
    }}>{`Lyon`}</h2>
    <ul>
      <li parentName="ul">{`So many things closed on the weekends`}</li>
    </ul>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/mJwMTrL9PXNup9Jj6"
        }}>{`Glacier Terre adélice LYON`}</a>{` - Line moved quickly`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-3"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VM6d2NDDG1wkxzuj7"
        }}>{`Gallo-Roman Museum of Lyon-Fourvière`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/NsJ9FZSTMkmSQHCr9"
            }}>{`Théâtre Gallo Romain de Lyon-Fourvière`}</a>{` is free and right outside the museum`}</li>
          <li parentName="ul">{`There are other attractions here that I didn't have time for`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wJjNKfo4pAAa9ZT29"
        }}>{`Museum and site of Saint-Romain-en-Gal Vienne`}</a>{` - 5 floors with an audio guide`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/5W3U7KSBHL81Ymxw6"
        }}>{`Museum of Cinema Miniature`}</a>{` - Spent about ~2 hours exploring 9 exhibits`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      